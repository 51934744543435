body {
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  background-color: rgb(237, 236, 236);
}

.slick-slide > div {
  margin: 0 20px;
}

.slick-list {
  margin: 0 -20px;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1rem;
  margin: 20px 0 0 0;
}

.slider__output {
  font-weight: 800;
  color: #cd9f2d;
}

.slider__total {
  font-size: 2rem;
  font-weight: 800;
  color: #cd9f2d;
  margin: 20px;
}

.interest-calculator {
  margin: 0 auto;
  padding: 20px;
  border: 0.2px solid rgb(128, 128, 128, 0.4);
  border-radius: 20px;
  text-align: center;
  background-color: rgb(237, 236, 236);
  margin-top: 5px;
  /* box-shadow: 8px 4px 5px gray; */
}

.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  width: 100%;
  height: 15px;
  background: #cd9f2d;
  outline: none;
  margin: 20px 0 10px 0;
  border-radius: 20px;
}

/* -webkit- (Chrome, Opera, Safari, Edge),  -moz- (Firefox) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 25px;
  height: 25px;
  background: #cd9f2d;
  cursor: pointer;
  border-radius: 20px;
}

.border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 15px;
}

.border-gradient-purple {
  border-image-source: linear-gradient(to left, #a6a5a8, #d53a9d);
}

.border-gradient-green {
  border-image-source: linear-gradient(to bottom, #dddbdb, #ffffff);
}

.only-top {
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}


.letter-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.animated-mail {
  position: absolute;
  height: 150px;
  width: 200px;
  -webkit-transition: .4s;
  -moz-transition: .4s;
  transition: .4s;
  
  .body {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 100px 200px;
    border-color: transparent transparent #d8ac3d transparent;
    z-index: 2;
  }
  
  .top-fold {
    position: absolute;
    top: 50px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 100px 0 100px;
    -webkit-transform-origin: 50% 0%;
    -webkit-transition: transform .4s .4s, z-index .2s .4s;
    -moz-transform-origin: 50% 0%;
    -moz-transition: transform .4s .4s, z-index .2s .4s;
    transform-origin: 50% 0%;
    transition: transform .4s .4s, z-index .2s .4s;
    border-color: #dda20d transparent transparent transparent;
    z-index: 2;
  }
  
  .back-fold {
    position: absolute;
    bottom: 0;
    width: 200px;
    height: 100px;
    background: #cd9f2d;
    z-index: 0;
  }
  
  .left-fold {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 0 50px 100px;
    border-color: transparent transparent transparent #cd9f2d;
    z-index: 2;
  }
  
  .letter {
    left: 20px;
    bottom: 0px;
    position: absolute;
    width: 160px;
    height: 60px;
    background: white;
    z-index: 1;
    overflow: hidden;
    -webkit-transition: .4s .2s;
    -moz-transition: .4s .2s;
    transition: .4s .2s;
    
    .letter-border {
      height: 10px;
      width: 100%;
      background: repeating-linear-gradient(
        -45deg,
        #cd9f2d,
        #cd9f2d 8px,
        transparent 8px,
        transparent 18px
      );
    }
    
    .letter-title {
      margin-top: 10px;
      margin-left: 5px;
      height: 10px;
      width: 40%;
      background: #cd9f2d;
    }
    .letter-context {
      margin-top: 10px;
      margin-left: 5px;
      height: 10px;
      width: 20%;
      background: #cd9f2d;
    }
    
    .letter-stamp {
      margin-top: 30px;
      margin-left: 120px;
      border-radius: 100%;
      height: 30px;
      width: 30px;
      background: #cd9f2d;
      opacity: 0.3;
    }
  }
}

.shadow {
  position: absolute;
  top: 200px;
  left: 50%;
  width: 400px;
  height: 30px;
  transition: .4s;
  transform: translateX(-50%);
  -webkit-transition: .4s;
  -webkit-transform: translateX(-50%);
  -moz-transition: .4s;
  -moz-transform: translateX(-50%);
  
  border-radius: 100%;
  background: radial-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.0), rgba(0,0,0,0.0));
}

  .letter-image:hover {
    .animated-mail {
      transform: translateY(50px);
      -webkit-transform: translateY(50px);
      -moz-transform: translateY(50px);
    }
    
    .animated-mail .top-fold {
      transition: transform .4s, z-index .2s;
      transform: rotateX(180deg);
      -webkit-transition: transform .4s, z-index .2s;
      -webkit-transform: rotateX(180deg);
      -moz-transition: transform .4s, z-index .2s;
      -moz-transform: rotateX(180deg);
      z-index: 0;
    }
    
    .animated-mail .letter {
      height: 180px;
    }
    
    .shadow {
      width: 250px;
    }
  }

